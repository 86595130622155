.top-header {
    padding-top: 5px;
    &::after {
        clear: both;
        display: block;
        content: "";
    }
    .header-left {
        float: left;
        @media (max-width: 767px) {
            float: none;
            text-align: center;
            padding-top: 15px;
            margin-bottom: 15px;
        }
    }
    .header-right {
        float: right;
        @media (max-width: 767px) {
            float: none;
            text-align: center;
            padding-bottom: 15px;
        }
    }
    .topHeader_item {
        ul {
            li {
                display: inline-block;
                margin-left: 15px;
                font-size: 14px;
                font-weight: 400;
                a {
                    color: #555555;
                    -webkit-transition: all 300ms ease-in;
                    -moz-transition: all 300ms ease-in;
                    -o-transition: all 300ms ease-in;
                    transition: all 300ms ease-in;
                    &:hover {
                        &.facebook {
                            color: #3b5998;
                        }
                        &.twitter {
                            color: #1da1f2;
                        }
                        &.youtube {
                            color: #ff0000;
                        }
                        color: $secondary-color;
                    }
                }
                &:first-child {
                    margin-left: 0px;
                }
            }
        }
    }
    .header_social_links {
        li {
            display: inline-block;
            padding: 0px 7px;
            border-radius: 4px;
            overflow: hidden;
            margin: 0px;

            // &.facebook {
            //   background: #3b5998;
            // }
            // &.twitter {
            //   background: #00aced;
            // }
            // &.youtube {
            //   background: #bb0000;
            // }
            a {
                color: #ffffff;
                font-size: 16px;
                color: #212121;
                -webkit-transition: all 300ms ease-in;
                -moz-transition: all 300ms ease-in;
                -o-stransition: all 300ms ease-in;
                transition: all 300ms ease-in;
                &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
    .topHeader_detail {
        li {
            color: #555555;
            span {
                display: inline-block;
                padding-right: 7px;
                color: $base-color;
            }
        }
    }
}
