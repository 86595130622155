.button {
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 15px 25px;
  font-weight: 600;
  border-radius: 4px;
  overflow: hidden;
}
.view_all {
  background-image: repeating-linear-gradient(
      333deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      45deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      135deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      90deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    linear-gradient(90deg, rgb(130, 26, 221), rgb(63, 178, 202));
  color: #ffffff;
  padding: 17px 45px;
  font-size: 18px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  @include transitions(300ms, ease-in);
  svg {
    position: absolute;
    right: -15px;
    top: 30%;
    transform: translateY(-30%);
    animation: shake 0.6s infinite;
    opacity: 0;
    @include transitions(300ms, ease-in);
  }
  &:hover {
    color: #ffffff;

    svg {
      opacity: 1;
      right: 15px;
      color: #ffffff;
    }
  }
}
.cancel_btn {
  background-image: linear-gradient(
      22.5deg,
      rgba(221, 221, 221, 0.04) 0%,
      rgba(221, 221, 221, 0.04) 56%,
      rgba(7, 7, 7, 0.04) 56%,
      rgba(7, 7, 7, 0.04) 100%
    ),
    linear-gradient(
      112.5deg,
      rgba(227, 227, 227, 0.04) 0%,
      rgba(227, 227, 227, 0.04) 14%,
      rgba(56, 56, 56, 0.04) 14%,
      rgba(56, 56, 56, 0.04) 100%
    ),
    linear-gradient(
      45deg,
      rgba(39, 39, 39, 0.04) 0%,
      rgba(39, 39, 39, 0.04) 34%,
      rgba(196, 196, 196, 0.04) 34%,
      rgba(196, 196, 196, 0.04) 100%
    ),
    linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.04) 0%,
      rgba(20, 20, 20, 0.04) 84%,
      rgba(75, 75, 75, 0.04) 84%,
      rgba(75, 75, 75, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(175, 31, 79), rgb(201, 35, 98));
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}
@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(1px, 1px) rotate(1deg);
  }
  100% {
    transform: translate(-1px, 1px) rotate(-1deg);
  }
}
