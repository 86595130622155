.services_section {
  p {
    line-height: 30px;
    //text-align: justify;
    color: #555555;
    margin-bottom: 20px;
  }
  ul {
    list-style: disc;
    padding-left: 15px;
    li {
      padding-bottom: 15px;
      color: #555555;
    }
  }
}
