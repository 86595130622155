.product_list_wrapper {
    .product-item {
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 1px rgba(0, 0, 0, 0.06);
        border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
}
.product_sidebar {
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 1px rgba(0, 0, 0, 0.06);
    border-top: 1px solid rgba(0, 0, 0, 0.06);

    position: sticky;
    top: 20px;
    @media (max-width: 767px) {
        margin-bottom: 30px;
    }
    ul {
        li {
            color: #555555;
            padding-bottom: 15px;
            text-transform: uppercase;
            border-bottom: 1px solid #dddddd;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;
            cursor: pointer;
            font-size: 15px;
            position: relative;
            font-size: 15px;
            &::after {
                position: absolute;
                content: "\f101";
                font-family: FontAwesome;
                right: 15px;
                top: 15px;
            }
            @include transitions(300ms, ease-in);
            &:hover {
                color: $secondary-color;
            }
        }
    }
}
.social_share_wrapper {
    position: fixed;
    z-index: 1;
    top: 80%;
    left: 0px;
    transform: translateY(-80%);
    ul {
        li {
            a {
                display: block;
                width: 45px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #ffffff;
                margin-bottom: 2px;
                // border: 1px solid transparent;
                -webkit-transition: all 300ms ease-in-out;
                -moz-transition: all 300ms ease-in-out;
                -o-transition: all 300ms ease-in-out;
                transition: all 300ms ease-in-out;
                &.facebook {
                    background-color: #3b5998;
                }
                &.twitter {
                    background-color: #1da1f2;
                }
                &.youtube {
                    background: #ff0000;
                }
                &:hover {
                    width: 60px;
                }
            }
        }
    }
}
.load_more_wrapper {
    margin-top: 20px;
}
.product-item .view_btn svg {
    display: none;
}
@media (min-width: 767px) and (max-width: 991px) {
    .product_list_wrapper {
        .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
