.form_wrapper {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    h1 {
        padding: 20px;
        border-bottom: 1px dashed #dddddd;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
        color: $form-label-color;
    }
    .form_inner_content {
        padding: 20px;
        .submit_btn {
            border: 0px;
            text-transform: uppercase;
            padding: 13px 25px;
            font-weight: 600;
            border-radius: 2px;
            background-color: #414bed;
            color: #ffffff;
        }
    }
}
.auth_form_wrapper {
    height: 100vh;
    background: #f9f9f9;
    .submit_btn {
        border: 0px;
        text-transform: uppercase;
        padding: 13px 25px;
        font-weight: 600;
        border-radius: 2px;
        background-color: #414bed;
        color: #ffffff;
        display: block;
        margin-top: 20px;
        width: 100%;
    }
}
.css-yk16xz-control {
    height: 45px;
    border-radius: 0px !important;
}
.field_array {
    position: relative;
    .remove_item {
        position: absolute;
        position: absolute;
        right: 0px;
        top: 28px;
        background: #dc3545 !important;
        color: #ffffff;
        font-size: 26px;
        border: 0px;
        width: 45px;
        height: 45px;
    }
    .add_item {
        position: absolute;
        top: 28px;
        background: #414bed;
        color: #ffffff;
        font-size: 26px;
        border: 0px;
        width: 45px;
        height: 45px;
        &.right-0 {
            right: 0px;
        }
        &.right-45 {
            right: 45px;
        }
    }
    .logout_button{
            background-color: #4CAF50; /* Green */
            border: none;
            color: white;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin: 4px 2px;
            cursor: pointer;
        }
}
