.admin_sidebar {
    height: 100vh;
    /* background: #36275d;
  /* background-image: radial-gradient( circle at 85% 1%, hsla(190, 0%, 93%, 0.05) 0%, hsla(190, 0%, 93%, 0.05) 96%, transparent 96%, transparent 100% ), radial-gradient( circle at 14% 15%, hsla(190, 0%, 93%, 0.05) 0%, hsla(190, 0%, 93%, 0.05) 1%, transparent 1%, transparent 100% ), radial-gradient( circle at 60% 90%, hsla(190, 0%, 93%, 0.05) 0%, hsla(190, 0%, 93%, 0.05) 20%, transparent 20%, transparent 100% ), radial-gradient( circle at 79% 7%, hsla(190, 0%, 93%, 0.05) 0%, hsla(190, 0%, 93%, 0.05) 78%, transparent 78%, transparent 100% ), radial-gradient( circle at 55% 65%, hsla(190, 0%, 93%, 0.05) 0%, hsla(190, 0%, 93%, 0.05) 52%, transparent 52%, transparent 100% ), linear-gradient(135deg, rgb(37, 56, 222), rgb(96, 189, 244)); */
    background-image: radial-gradient(
            circle at 52% 33%,
            rgba(17, 17, 17, 0.08) 0%,
            rgba(17, 17, 17, 0.08) 33.333%,
            rgba(74, 74, 74, 0.08) 33.333%,
            rgba(74, 74, 74, 0.08) 66.666%,
            rgba(130, 130, 130, 0.08) 66.666%,
            rgba(130, 130, 130, 0.08) 99.999%
        ),
        radial-gradient(
            circle at 70% 65%,
            rgba(129, 129, 129, 0.08) 0%,
            rgba(129, 129, 129, 0.08) 33.333%,
            rgba(95, 95, 95, 0.08) 33.333%,
            rgba(95, 95, 95, 0.08) 66.666%,
            rgba(60, 60, 60, 0.08) 66.666%,
            rgba(60, 60, 60, 0.08) 99.999%
        ),
        radial-gradient(
            circle at 97% 89%,
            rgba(21, 21, 21, 0.08) 0%,
            rgba(21, 21, 21, 0.08) 33.333%,
            rgba(89, 89, 89, 0.08) 33.333%,
            rgba(89, 89, 89, 0.08) 66.666%,
            rgba(156, 156, 156, 0.08) 66.666%,
            rgba(156, 156, 156, 0.08) 99.999%
        ),
        linear-gradient(0deg, rgb(17, 9, 206), rgb(65, 75, 237));
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    width: 240px;
    position: fixed;
    left: 0px;
    overflow: scroll;
    top: 0px;
    &::-webkit-scrollbar {
        display: none;
    }
    .sidebar_logo {
        text-align: center;
        height: 80px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgb(65, 75, 237);
        margin-bottom: 40px;
        h3 {
            color: #ffffff;
        }
    }
    .sidebar_menu {
        ul {
            li {
                .dropdown {
                    display: none;
                    transition: all 300ms ease;
                    &.show {
                        display: block;
                    }
                }
                &.active {
                    button {
                        background-color: #2276d0;
                        transition: all 300ms ease;
                    }
                    .dropdown {
                        background-color: #2276d0;
                        transition: all 300ms ease;
                    }
                }
                button {
                    background-color: transparent;
                    border: 0px;
                    color: #ffffff;
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 20px;
                    border-bottom: 1px solid rgb(65, 75, 237);
                    width: 100%;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 16px;
                    transition: all 300ms ease;
                    span {
                        position: relative;
                        top: 7px;
                        color: #ffffff;
                    }
                }
                a {
                    color: #ffffff;
                    display: block;
                    padding: 15px 20px;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 16px;
                    border-bottom: 1px solid rgb(65, 75, 237);
                    transition: all 300ms ease;
                }
            }
        }
    }
}
.sidebar_logo {
    img {
        height: 50px;
        width: 100px;
        object-fit: contain;
    }
}
