.popup-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.8);
    top: 0px;
    left: 0px;
    display: none;
    &.show {
        display: block;
    }
    .popup-content-wrapper {
        max-width: 900px;
        background: #ffffff;
        width: 100%;
        top: 50%;
        left: 50%;
        border-radius: 4px;
        overflow: hidden;
        position: absolute;
        transform: translate(-50%, -50%);
        border: 1px solid #dddddd;
        .col-md-12,
        .col-md-6,
        .col-md-4 {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .popup_header {
        padding: 10px;
        border-bottom: 1px solid #dddddd;
        h3 {
            font-size: 22px;
            font-weight: 600;
            color: $secondary-color;
        }
    }
    .popup_content {
        padding: 10px 20px;
        .form-group {
            .form-control {
                height: 47px;
            }
            textarea.form-control {
                height: 150px;
            }
        }
    }
    .popup_footer {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #dddddd;
        padding: 10px;
        background: #f7f7f7;
    }
}
