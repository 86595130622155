.box-content-wrapper {
  .form-group {
    label {
      margin-bottom: 10px;
    }
    .form_image {
      height: 45px;
      border: 1px solid #ced4da;
      padding-left: 15px;
      line-height: 45px;
      color: #495057;
      p {
        font-size: 14px;
        text-transform: capitalize;
      }
    }
    .radio_btn_wrapper {
      display: flex;
      border: 1px solid #ced4da;
      padding-left: 15px;
      line-height: 35px;
      background: #f9f9f9;
      color: #495057;
      .radio_item {
        margin-right: 15px;
        input {
          position: relative;
          top: 2px;
          margin-right: 5px;
        }
      }
    }
  }
}
