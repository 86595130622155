.map_wrapper {
    .leaflet-container {
        height: 500px;
    }
}
.contact_info {
    ul {
        li {
            padding: 15px;
            background: #ffffff;
            position: relative;
            padding-left: 60px;
            @media (max-width: 767px) {
                background: transparent;
            }
            svg {
                color: #000;
                position: absolute;
                left: 0px;
                font-size: 34px;
                top: 45px;
                opacity: 0.3;
            }
            h3 {
                font-size: 22px;
                text-transform: uppercase;
                color: #212121;
                margin-bottom: 10px;
                margin-top: 15px;
            }
            p {
                color: #555555;
            }
        }
    }
}
.contact_form {
    padding: 25px;
    background: #ffffff;
}
.map_wrapper {
    &::after {
        clear: both;
        display: block;
        content: "";
    }
    .map_section {
        float: left;
        width: 60%;
        box-sizing: border-box;
        @media (max-width: 767px) {
            width: 85%;
            margin: 0 40px;
        }
    }
    .contact_information_detail_section {
        float: right;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 60px;
        background-color: #ffffff;
        @media (max-width: 767px) {
            width: 100%;
            background: #f7f7f7;
            float: none;
            padding-top: 0px;
            padding: 40px 0;
        }
    }
}
.form_inner_content {
    .error {
        color: #dc3545 !important;
        font-size: 14px;
    }
    .view_all {
        border: 0px;
    }
}
