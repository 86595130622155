.dashboard_item {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 30px;
}
.list_item_wrapper {
    ul {
        &::after {
            clear: both;
            display: block;
            content: "";
        }
        li {
            float: left;
            width: 25%;
            box-sizing: border-box;
            padding-left: 15px;
            padding-right: 15px;
            &:first-child {
                padding-left: 0px;
                .list_item {
                    background-color: #418bca !important;
                }
            }
            &:nth-child(2) {
                .list_item {
                    background-color: #16a085 !important;
                }
            }
            &:nth-child(3) {
                .list_item {
                    background-color: #e05d6f !important;
                }
            }
            &:nth-child(4) {
                padding-right: 0px;
                .list_item {
                    background-color: #418bca !important;
                }
            }
        }
    }
}
.list_item {
    padding: 20px;
    margin-bottom: 30px;
    color: #ffffff;
    height: 120px;
    align-items: center;
    justify-content: center;
}
.list_item {
    display: flex;
    .item_left {
        margin-right: 20px;
        font-size: 50px;
        font-weight: 600;
    }
    .item_right {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
    }
}
