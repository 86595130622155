.navbar-bg {
    background: transparent !important;
    .navbar-brand {
        img {
            width: 120px;
        }
    }
    .nav-item {
        padding: 10px 20px;
        @media (max-width: 1160px) {
            padding: 10px !important;
        }
        &:last-child {
            padding-right: 0px;
        }
        .nav-link {
            color: $span-color !important;
            // font-weight: 600;
            -o-transition: all 300ms ease-in;
            -webkit-transition: all 300ms ease-in;
            -moz-transition: all 300ms ease-in;
            transition: all 300ms ease-in;
            font-weight: 500;
            &.enquiry_btn {
                border: 0px;
                border-radius: 25px;
                padding-left: 25px;
                padding-right: 25px;
                color: #ffffff !important;
                background-image: repeating-linear-gradient(
                        333deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        45deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        135deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        0deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        90deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    linear-gradient(90deg, rgb(130, 26, 221), rgb(63, 178, 202));
                position: relative;
                overflow: hidden;
                &:hover,
                &:active {
                    color: #ffffff !important;
                    // animation: shake 0.6s infinite;
                }
                svg {
                    position: absolute;
                    right: -15px;
                    top: 30%;
                    transform: translateY(-30%);
                    animation: shake 0.6s infinite;
                    opacity: 0;
                    @include transitions(300ms, ease-in);
                }
            }
            &:hover,
            &:active {
                color: $secondary-color !important;
            }
            &:hover {
                svg {
                    opacity: 1;
                    right: 7px;
                    font-size: 13px;
                    color: #ffffff;
                }
            }
        }
    }
}

@keyframes shake {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    25% {
        transform: translate(1px, 1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, -1px) rotate(-1deg);
    }
    75% {
        transform: translate(2px, -1px) rotate(2deg);
    }
    100% {
        transform: translate(-1px, 1px) rotate(-1deg);
    }
}
.nav-item {
    .nav-link.active {
        color: $secondary-color !important;
    }
}
@media (max-width: 767px) {
    .navbar-toggler {
        background: #b5b5b5;
    }
    .showMenu {
        display: block !important;
    }
    .navbar-nav {
        border-top: 1px solid #dddddd;
        margin-top: 20px;
    }
    .popup-wrapper {
        overflow-y: scroll;
    }
}
