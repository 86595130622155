body {
    font-family: $base-font;
    width: 100%;
    position: relative;
    margin: 0px;
    padding: 0px;
    color: #000000;
    font-weight: 400;
    font-size: $base-font-size;
}
a {
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}
img {
    width: 100%;
}
figure {
    margin-bottom: 0px;
}
.bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.overlay {
    position: relative;
    z-index: 1;
    &::after {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
}
ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.bg-gray {
    background-color: $bg-gray;
}
.margin-0 {
    margin: $margin-0;
}
.padding-0 {
    padding: $padding-0;
}
.padd-top-0 {
    padding-top: 0px;
}
.padd-btm-0 {
    padding-bottom: 0;
}
.margin-top-0 {
    margin-top: 0px;
}
.margin-btm-0 {
    margin-bottom: 0;
}

.section {
    padding: $section-padding;
}
h1 {
    font-size: $h1-font-size;
    margin: 0px;
    padding: 0px;
}
h3 {
    font-size: $h3-font-size;
    margin: 0px;
    padding: 0px;
}
p {
    font-size: $p-font-size;
    margin: 0px;
    padding: 0px;
}
span {
    //   font-size: 15px;
    color: $span-color;
}
.section-title {
    h3 {
        font-weight: 600;
        color: $secondary-color;
    }
}
.box-shadow {
    box-shadow: $box-shadow;
}
.pos-relative {
    position: $pos-relative;
}
.pos-absolute {
    position: $pos-absolute;
}
.bg-gray-light {
    background-color: $bg-gray-light;
}
.Toastify__toast {
    height: 50px;
    line-height: 35px;
    padding-left: 20px;
    min-height: unset !important;
    padding-right: 10px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
}
.Toastify__toast-container {
    width: unset;
}
.Toastify__toast-body {
    padding-right: 15px;
}
.Toastify__close-button {
    position: relative;
    top: -3px;
}
.loader_wrapper {
    height: 100vh;
    top: 0px;
    background: #ffffff;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0px;
    z-index: 9999;
    .loader_inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1 {
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-name: bounce-3;
            animation-timing-function: ease;
        }
    }
}
@keyframes bounce-3 {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(15px);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(15px);
    }
}
.client-section {
    .slider_item {
        figure {
            height: 100px;
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }
}
.load_more_wrapper {
    ul {
        li {
            display: inline-block;
            &:first-child {
                a {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            &:last-child {
                a {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
            a {
                padding: 7px 15px;
                border: 1px solid #dbe0e2;
                color: #616f77 !important;
                display: block;
                border-right: 0px;
            }
            &:last-child {
                a {
                    border-right: 1px solid #dbe0e2;
                }
            }
        }
    }
}
.remove {
    display: none;
}
