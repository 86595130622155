.product-item {
    background: #ffffff;
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 4px;
    // padding-top: 10px;
    -webkit-transition: all 300ms ease-in;
    -moz-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    figure {
        height: 220px;
        overflow: hidden;
        margin-bottom: 15px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    &:hover {
        .view_btn {
            background-color: $base-color;
            color: #ffffff;
        }
        .product-content {
            h5 {
                color: $secondary-color;
            }
        }
    }
    .product-content {
        text-align: center;
        padding-top: 5px;
        h5 {
            margin-bottom: 25px;
            text-transform: capitalize;
            color: #555555;
            font-size: 16px;
            // font-family: $secondary-font;
            font-weight: 400;
            @include transitions(300ms, ease-in);
        }
    }
    .view_btn {
        width: 100%;
        background: #efefef;
        display: block;
        text-align: center;
        color: #555555;
        border-radius: 0px;
        -webkit-transition: all 300ms ease-in;
        -moz-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
        font-weight: 500;
    }
}
.product_slider {
    .slick-arrow {
        display: none;
    }
    .slick-dots {
        li {
            &.slick-active {
                button {
                    &::before {
                        color: $secondary-color !important;
                    }
                }
            }
            button {
                &::before {
                    font-size: 14px;
                }
            }
        }
    }
    .product-item {
        margin-left: 5px;
        margin-right: 5px;
        -webkit-transition: all 300ms ease-in;
        -moz-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
        &:hover {
            transform: scale(1);
        }
        h5 {
            font-size: 17px;
        }
        .view_btn {
            font-size: 15px;
            font-weight: 600;
        }
    }
}
.detail-product-img {
    // border: 1px solid #dddddd;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 1px rgba(0, 0, 0, 0.06);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    overflow: hidden;
}
.product-img-dots {
    // position: unset;
    // bottom: unset;
    left: 0px;
    // margin-top: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: unset;
    li {
        width: 60px;
        height: 60px;
        border: 1px dashed #ddd;
        border-radius: 4px;
        display: block;
        margin-bottom: 10px;

        overflow: hidden;
        .dots-wrapper {
            height: 100%;
            width: 100%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}
.product-info-wrapper {
    // padding-left: 30px;
    h3 {
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $secondary-color;
        font-weight: 600;
    }
    p {
        text-align: justify;
        line-height: 28px;
        color: #555555;
        margin-bottom: 15px;
        font-size: 15px;
    }
}
.product_detail_wrapper {
    position: relative;
    padding-bottom: 30px;
    z-index: 1;
    &::after {
        position: absolute;
        content: "";
        width: 50%;
        height: 100%;
        // background: #f7f7f7;
        left: 0px;
        top: 0px;
        z-index: -1;
    }
    .slick-arrow {
        display: none !important;
    }
}

.related_products_section {
    .section-title {
        margin-bottom: 60px;
    }
    .product-item {
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 1px rgba(0, 0, 0, 0.06);
        border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
}
.specification_wrapper {
    .react-tabs__tab-list {
        border: 0px;
        background: #ffffff;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: $box-shadow;
        .react-tabs__tab--selected {
            border: 0px;
            border-radius: 0px;
            background-image: repeating-linear-gradient(
                    333deg,
                    hsla(119, 47%, 93%, 0.05) 0px,
                    hsla(119, 47%, 93%, 0.05) 1px,
                    transparent 1px,
                    transparent 11px,
                    hsla(119, 47%, 93%, 0.05) 11px,
                    hsla(119, 47%, 93%, 0.05) 12px,
                    transparent 12px,
                    transparent 32px
                ),
                repeating-linear-gradient(
                    45deg,
                    hsla(119, 47%, 93%, 0.05) 0px,
                    hsla(119, 47%, 93%, 0.05) 1px,
                    transparent 1px,
                    transparent 11px,
                    hsla(119, 47%, 93%, 0.05) 11px,
                    hsla(119, 47%, 93%, 0.05) 12px,
                    transparent 12px,
                    transparent 32px
                ),
                repeating-linear-gradient(
                    135deg,
                    hsla(119, 47%, 93%, 0.05) 0px,
                    hsla(119, 47%, 93%, 0.05) 1px,
                    transparent 1px,
                    transparent 11px,
                    hsla(119, 47%, 93%, 0.05) 11px,
                    hsla(119, 47%, 93%, 0.05) 12px,
                    transparent 12px,
                    transparent 32px
                ),
                repeating-linear-gradient(
                    0deg,
                    hsla(119, 47%, 93%, 0.05) 0px,
                    hsla(119, 47%, 93%, 0.05) 1px,
                    transparent 1px,
                    transparent 11px,
                    hsla(119, 47%, 93%, 0.05) 11px,
                    hsla(119, 47%, 93%, 0.05) 12px,
                    transparent 12px,
                    transparent 32px
                ),
                repeating-linear-gradient(
                    90deg,
                    hsla(119, 47%, 93%, 0.05) 0px,
                    hsla(119, 47%, 93%, 0.05) 1px,
                    transparent 1px,
                    transparent 11px,
                    hsla(119, 47%, 93%, 0.05) 11px,
                    hsla(119, 47%, 93%, 0.05) 12px,
                    transparent 12px,
                    transparent 32px
                ),
                linear-gradient(90deg, rgb(130, 26, 221), rgb(63, 178, 202));
            color: #ffffff !important;
        }
        .react-tabs__tab {
            text-transform: uppercase;
            color: #555555;
            padding: 15px 25px;
            font-weight: 600;
            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;
            @media (max-width: 767px) {
                display: block;
            }
            &:hover {
                background-image: repeating-linear-gradient(
                        333deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        45deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        135deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        0deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    repeating-linear-gradient(
                        90deg,
                        hsla(119, 47%, 93%, 0.05) 0px,
                        hsla(119, 47%, 93%, 0.05) 1px,
                        transparent 1px,
                        transparent 11px,
                        hsla(119, 47%, 93%, 0.05) 11px,
                        hsla(119, 47%, 93%, 0.05) 12px,
                        transparent 12px,
                        transparent 32px
                    ),
                    linear-gradient(90deg, rgb(130, 26, 221), rgb(63, 178, 202));
                color: #ffffff !important;
            }
        }
    }
    .react-tabs__tab-panel {
        background: #ffffff;
        padding: 10px 15px;
        margin-top: 20px;
        text-align: justify;
        box-shadow: $box-shadow;
        color: #555555;
        p {
            margin-bottom: 20px;
            font-size: 15px;
            line-height: 30px;
        }
    }
}
.featured_tabs_product {
    .product_slider .slick-arrow {
        display: none !important;
    }
}
.product-info-wrapper ul {
    margin-top: 15px;
}
.product-info-wrapper ul li {
    font-size: 15px;
}
@media (max-width: 767px) {
    .product-info-wrapper {
        margin-top: 30px;
    }
}
