.about-section-wrapper {
    z-index: 1;
    overflow: hidden;
    .about-items {
        margin-top: 20px;
        &::after {
            clear: both;
            display: block;
            content: "";
        }
        li {
            width: 50%;
            float: left;
            padding-top: 15px;
            font-weight: 400;
            position: relative;
            color: #555555;
            padding-left: 18px;
            &::before {
                position: absolute;
                left: 0px;
                top: 25px;
                width: 5px;
                height: 5px;
                background: #555;
                content: "";
                border-radius: 50%;
            }
        }
    }
    .about-img {
        padding: 0 25px;
        figure {
            overflow: hidden;
            border-radius: 4px;
            height: 350px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .button {
        margin-top: 30px;
    }
    z-index: 1;
    overflow: hidden;
    h3 {
        margin-bottom: 20px;
    }
    p {
        //text-align: justify;
        line-height: 28px;
        color: #555555;
    }
    &::after {
        position: absolute;
        content: "";
        right: -100px;
        top: 0px;
        width: 38%;
        height: 110%;
        background-image: repeating-linear-gradient(
                333deg,
                hsla(119, 47%, 93%, 0.05) 0px,
                hsla(119, 47%, 93%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(119, 47%, 93%, 0.05) 11px,
                hsla(119, 47%, 93%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            repeating-linear-gradient(
                45deg,
                hsla(119, 47%, 93%, 0.05) 0px,
                hsla(119, 47%, 93%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(119, 47%, 93%, 0.05) 11px,
                hsla(119, 47%, 93%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            repeating-linear-gradient(
                135deg,
                hsla(119, 47%, 93%, 0.05) 0px,
                hsla(119, 47%, 93%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(119, 47%, 93%, 0.05) 11px,
                hsla(119, 47%, 93%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            repeating-linear-gradient(
                0deg,
                hsla(119, 47%, 93%, 0.05) 0px,
                hsla(119, 47%, 93%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(119, 47%, 93%, 0.05) 11px,
                hsla(119, 47%, 93%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            repeating-linear-gradient(
                90deg,
                hsla(119, 47%, 93%, 0.05) 0px,
                hsla(119, 47%, 93%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(119, 47%, 93%, 0.05) 11px,
                hsla(119, 47%, 93%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            linear-gradient(90deg, rgb(130, 26, 221), rgb(63, 178, 202));
        z-index: -1;
        transform: skew(-15deg);
        @media (max-width: 767px) {
            display: none;
        }
    }
}
.product_wrapper {
    margin-top: 50px;
}
.new_arrival_section {
    .section-title {
        margin-bottom: 60px;
    }
}
.client-section {
    .section-title {
        margin-bottom: 60px;
    }
}
.testimonials_wrapper {
    margin-top: 60px;
}
.slider_item {
    margin: 0 10px;
    box-shadow: $box-shadow;
    border: 1px solid #dddddd;
}
.testimonial-wrapper {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 35%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 1;
        @media (max-width: 767px) {
            display: none;
        }
    }
    &::after {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        height: 100%;
        width: 35%;
        background-image: url(../../images/testimonial-bg.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 767px) {
            display: none;
        }
    }
}
.new_arrival_section {
    .btn-wrapper {
        text-align: center;
        // margin-top: 30px;
    }
}
.categories_section_wrapper {
    .section-title {
        margin-bottom: 60px;
    }
}
.category_item {
    margin: 10px;
    cursor: pointer;
}
.category-content {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    background: #efefef;
    h3 {
        font-size: 17px;
        font-family: $secondary-font;
        font-weight: 400;
        color: #555555;
    }
    span {
        color: $base-color;
        &.outofstock {
            color: $secondary-color;
        }
    }
}
// .product-section {
//   .product-item {
//     box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1),
//       0 1px 2px 1px rgba(0, 0, 0, 0.06);
//     border-top: 1px solid rgba(0, 0, 0, 0.06);
//   }
// }
.testimonial-wrapper {
    background-image: linear-gradient(
            44deg,
            rgba(114, 114, 114, 0.02) 0%,
            rgba(114, 114, 114, 0.02) 86%,
            transparent 86%,
            transparent 87%,
            rgba(147, 147, 147, 0.02) 87%,
            rgba(147, 147, 147, 0.02) 91%,
            rgba(63, 63, 63, 0.02) 91%,
            rgba(63, 63, 63, 0.02) 100%
        ),
        linear-gradient(
            78deg,
            rgba(51, 51, 51, 0.02) 0%,
            rgba(51, 51, 51, 0.02) 51%,
            transparent 51%,
            transparent 74%,
            rgba(43, 43, 43, 0.02) 74%,
            rgba(43, 43, 43, 0.02) 94%,
            rgba(36, 36, 36, 0.02) 94%,
            rgba(36, 36, 36, 0.02) 100%
        ),
        linear-gradient(
            166deg,
            rgba(152, 152, 152, 0.02) 0%,
            rgba(152, 152, 152, 0.02) 16%,
            transparent 16%,
            transparent 51%,
            rgba(109, 109, 109, 0.02) 51%,
            rgba(109, 109, 109, 0.02) 93%,
            rgba(132, 132, 132, 0.02) 93%,
            rgba(132, 132, 132, 0.02) 100%
        ),
        linear-gradient(
            80deg,
            rgba(136, 136, 136, 0.02) 0%,
            rgba(136, 136, 136, 0.02) 28%,
            transparent 28%,
            transparent 35%,
            rgba(226, 226, 226, 0.02) 35%,
            rgba(226, 226, 226, 0.02) 95%,
            rgba(171, 171, 171, 0.02) 95%,
            rgba(171, 171, 171, 0.02) 100%
        ),
        linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
    .slick-arrow {
        display: none !important;
    }
}
.featured_tabs_product {
    text-align: center;
    .react-tabs__tab-list {
        border: 0px;
        margin-bottom: 50px;
    }
    .react-tabs__tab--selected {
        border: 0px;
        background: $base-color !important;
        color: #ffffff !important;
    }
    .react-tabs__tab {
        text-transform: uppercase;
        color: #555555;
        font-weight: 500;
        font-size: 15px;
        padding: 10px 30px;
        margin: 0 10px;
        border-radius: 25px;
        background: #ffffff;
        -webkit-transition: all 300ms ease-in;
        -moz-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
        @media (max-width: 767px) {
            display: block;
            margin-bottom: 20px;
        }
        &:hover {
            background: $base-color !important;
            color: #ffffff;
        }
    }
}
.home_testinomials {
    background-image: none;
}
.client-section {
    .slick-arrow {
        display: none !important;
    }
}
.category_wrapper {
    margin-top: 60px;
}
.category_section {
    .btn-wrapper {
        margin-top: 50px;
        text-align: center;
    }
    ul {
        &::after {
            clear: both;
            display: block;
            content: "";
        }
        li {
            float: left;
            width: 20%;
            box-sizing: border-box;
            padding-left: 5px;
            padding-right: 5px;
            @media (max-width: 991px) {
                width: 33.3333333333%;
            }
            @media (max-width: 767px) {
                width: 50%;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
            figure {
                height: 250px;
                overflow: hidden;
                border-radius: 4px;
                position: relative;
                z-index: 1;
                @include transitions(300ms, ease-in);
                &::after {
                    position: absolute;
                    content: "";
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: #000000, $alpha: 0.2);
                }
                &:hover {
                    h4 {
                        color: $base-color;
                    }
                }
                h4 {
                    position: absolute;
                    bottom: 0px;
                    background: rgba($color: #000000, $alpha: 0.6);
                    color: #ffffff;
                    padding: 10px 15px;
                    font-size: 16px;
                    width: 100%;
                    z-index: 9;
                    margin: 0px;
                    font-weight: 400;
                    @include transitions(300ms, ease-in);
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
    }
}
